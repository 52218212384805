import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
// import reportWebVitals from './reportWebVitals';
import '../src/assets/css/custom.css';
import 'bootstrap/dist/css/bootstrap.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

import { Provider } from 'react-redux';
import Store from './redux/store';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={Store}>
        <App />

    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
