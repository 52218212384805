import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../component/Navbar/navbar'
import { NavLink } from 'react-router-dom';


import TinySlider from 'tiny-slider-react';


import ModalVideo from 'react-modal-video'
// import "../../../node_modules/react-modal-video/css/modal-video.css";




import { TypeAnimation } from 'react-type-animation';


export default function IndexLandingFour() {

    const [toggleState, setToggleState]= useState(1);
   


    const toggleTab = (index)=>{
        setToggleState(index)
    }

  
    const closeNav = ()=>{
        document.getElementsByClassName('sidebar')[0].style.width="0%";
        document.getElementsByClassName('sidebar')[0].style.opacity="0";
        document.getElementsByClassName('sidebar')[0].style.padding="0px";

      }
      const closeNav2 = ()=>{
        document.getElementsByClassName('leftbar')[0].style.width="0%";
        document.getElementsByClassName('leftbar')[0].style.opacity="0";

      }

  
     
    const openNav = () => {
        document.getElementsByClassName('sidebar')[0].style.width="320px";
        document.getElementsByClassName('sidebar')[0].style.opacity="1";
        document.getElementsByClassName('sidebar')[0].style.padding="20px";

      };

      const openNav2 = () => {
        document.getElementsByClassName('leftbar')[0].style.width="320px";
        document.getElementsByClassName('leftbar')[0].style.opacity="1";

      };

     
    



    const settings = {
        container: '.tiny-five-item',
        controls: false,
        mouseDrag: true,
        
        loop: true,
        rewind: false,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            1025: {
                items: 3
            },
    
            992: {
                items: 2
            },
    
            767: {
                items: 1
            },
    
            425: {
                items: 2
            },

            

          
        },
    }

    const settings2 = {
        container: '.tiny-five-item',
        controls: true,
        mouseDrag: true,
        
        loop: true,
        rewind: false,
        autoplay: false,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            1200: {
                items: 7
            },
            1025: {
                items: 6
            },
    
            992: {
                items: 6
            },
    
            767: {
                items: 4
            },

            425: {
                items: 3
            },
    
            320: {
                items: 2
            }
        },
    }
  
    
   

    
    return (
        <>


        <div className='mobile-header'>
            <div className=''>
                <div className='row p-0'>
                    <div className='col-12'>
                        <div>
                            <ul className='d-flex align-items-center'>
                                <li><button className='bg-none home-btn text-orange' onClick={openNav2}><i class="fa fa-home" aria-hidden="true"></i></button></li>
                                <li className='search' data-toggle="modal" data-target="#exampleModal"><i class="fa fa-search" aria-hidden="true"></i></li>
                                <li><a href='/'><img src='images/logo.png' className='logo'></img></a></li>
                                <li className='relative'><a href='/cart'><img src='images/bag.png' className='header_img order_bag' ></img></a> <div className='added_order'>1</div>
                            </li>
                                <li><img src='images/more.png' className='header_img more-img cursor-pointer'  onClick={openNav}></img></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <Navbar />


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
        <input className='header_input' placeholder='Search'></input>
    </div>
  </div>
</div>
            <section className='hero_section'>
                <div className="grid grid-cols-1 mt-2 md:mt-6 relative">
                            <div className="tiny-five-item d-flex overflow-hidden2">
                            <TinySlider settings={settings} className='d-flex'>
                                    <div className="tiny-slide" >
                                        <div className="group relative p-2 radius-8 overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                            <img src='images/img8.jpg' className='width100 radius-4'></img>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" >
                                        <div className="group relative p-2 radius-8 overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                            <img src='images/food2.jpg' className='width100 radius-4'></img>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" >
                                        <div className="group relative p-2 radius-8 overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                            <img src='images/food.jpg' className='width100 radius-4'></img>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" >
                                        <div className="group relative p-2 radius-8 overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                            <img src='images/img8.jpg' className='width100 radius-4'></img>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" >
                                        <div className="group relative p-2 radius-8 overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                            <img src='images/food2.jpg' className='width100 radius-4'></img>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" >
                                        <div className="group relative p-2 radius-8 overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                            <img src='images/food.jpg' className='width100 radius-4'></img>
                                        </div>
                                    </div>
                            </TinySlider>
                        </div>
                <div className='container'>
                    {/* <div className='man_box mt-4 shadow border radius-8 relative'>
                      
                    </div> */}

                        <div className='row  p-4 '>
                            <img src='images/bg2.png' className='bg-image'></img>
                            <img src='images/bg3.png' className='bg-image2'></img>
                            <div className='col-md-12'>
                                <div className='overflow-hidden2'>
                                {/* <div class="tns-controls" aria-label="Carousel Navigation" tabindex="0" >
                                    <button type="button" data-controls="prev" tabindex="-1" aria-controls="tns18"><i class="mdi mdi-chevron-left "></i></button>
                                    <button type="button" data-controls="next" tabindex="-1" aria-controls="tns18"><i class="mdi mdi-chevron-right"></i></button>
                                </div> */}

                                    <TinySlider settings={settings2} className='d-flex recipe-list-box'>
                                  
                                    <div className="tiny-slide" onClick={()=>toggleTab(1)}>
                                        <div className='text-decoration-none' >
                                        <div className=' offer radius-8 '>
                                            <img src='images/menu1.png'className='width100 menu-list'></img>
                                            <p class="normal text-gray menu-list-name">Chicken Korma</p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" onClick={()=>toggleTab(2)}>
                                        <div className='text-decoration-none' >
                                        <div className=' offer radius-8 '>
                                            <img src='images/menu2.png'className='width100 menu-list'></img>
                                            <p class="normal text-gray menu-list-name">Chicken Korma </p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" onClick={()=>toggleTab(3)}>
                                        <div className='text-decoration-none' >
                                        <div className=' offer radius-8 '>
                                            <img src='images/menu3.png'className='width100 menu-list'></img>
                                            <p class="normal text-gray menu-list-name">Swami Kheer </p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" onClick={()=>toggleTab(4)}>
                                        <div className='text-decoration-none' >
                                        <div className=' offer radius-8 '>
                                            <img src='images/menu4.png'className='width100 menu-list'></img>
                                            <p class="normal text-gray menu-list-name">Daal Makhni </p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" onClick={()=>toggleTab(5)}>
                                        <div className='text-decoration-none' >
                                        <div className=' offer radius-8 '>
                                            <img src='images/menu5.png'className='width100 menu-list'></img>
                                            <p class="normal text-gray menu-list-name">Bread Gem </p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" onClick={()=>toggleTab(6)}>
                                        <div className='text-decoration-none' >
                                        <div className=' offer radius-8 '>
                                            <img src='images/menu6.png'className='width80 menu-list'></img>
                                            <p class="normal text-gray menu-list-name">Gulab Jamun</p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" onClick={()=>toggleTab(7)}>
                                        <div className='text-decoration-none' >
                                        <div className=' offer radius-8 '>
                                            <img src='images/menu1.png'className='width100 menu-list'></img>
                                            <p class="normal text-gray menu-list-name">Chiken Biryani </p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" onClick={()=>toggleTab(8)}>
                                        <div className='text-decoration-none' >
                                        <div className=' offer radius-8 '>
                                            <img src='images/menu1.png'className='width100 menu-list'></img>
                                            <p class="normal text-gray menu-list-name">Chiken Biryani </p>
                                        </div>
                                        </div>
                                    </div>
                                </TinySlider>
                                </div>
                                <hr></hr>
                                <section className='man-section-list'>
                                    <section className={toggleState === 1 ? "list-boxes active-list":""}>
                                        <div className='d-flex justify-between mt-4 align-items-center'>
                                            <div className=''>
                                                <h4 className='sub-heading text-orange mb-0'>All Recipe</h4>
                                                <img src='images/heading.png' className='width50'></img>
                                            </div>
                                            <button className='filter-btn'><img src='images/filter.png' className='width15'></img>Filters</button>
                                        </div>

                                        <div className='row mt-5'>
                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food2.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/img8.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>
                                            

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food2.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            
                                        </div>

                                        <div className='row mt-5'>
                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/img8.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            
                                        </div>

                                        <div className='row mt-5'>
                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food2.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6 sm-mt-4'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className={toggleState === 2 ? 'list-boxes active-list':"list-boxes"}>
                                        <div className='d-flex justify-between mt-4 align-items-center'>
                                            <div className=''>
                                                <h4 className='sub-heading text-orange'>Chicken Korma</h4>
                                            <img src='images/heading.png' className='width20'></img>

                                                <ul className='filter-tab'>
                                                    <li>Filters</li>
                                                    <li>Sort By</li>
                                                    <li>Pure Veg </li>
                                                    <li>Offer </li>
                                                    <li>Rs. 200-Rs.600 </li>
                                                    <li>Rating 4.0+ </li>
                                                </ul>
                                            </div>
                                        </div>

                                        

                                        <div className='row mt-5'>
                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            
                                        </div>

                                        <div className='row mt-5'>
                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food2.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className={toggleState === 3 ? 'list-boxes active-list':"list-boxes"}>
                                        <div className='d-flex justify-between mt-4 align-items-center'>
                                            <div className=''>
                                                <h4 className='sub-heading text-orange'>Swami Kheer</h4>
                                            <img src='images/heading.png' className='width20'></img>
                                                <ul className='filter-tab'>
                                                    <li>Filters</li>
                                                    <li>Sort By</li>
                                                    <li>Pure Veg </li>
                                                    <li>Offer </li>
                                                    <li>Rs. 200-Rs.600 </li>
                                                    <li>Rating 4.0+ </li>
                                                </ul>
                                            </div>
                                        </div>

                                        

                                        <div className='row mt-5'>
                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            
                                        </div>

                                        <div className='row mt-5'>
                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food2.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className={toggleState === 4 ? 'list-boxes active-list':"list-boxes"}>
                                        <div className='d-flex justify-between mt-4 align-items-center'>
                                            <div className=''>
                                                <h4 className='sub-heading text-orange'>Daal Makhni</h4>
                                            <img src='images/heading.png' className='width20'></img>
                                                <ul className='filter-tab'>
                                                    <li>Filters</li>
                                                    <li>Sort By</li>
                                                    <li>Pure Veg </li>
                                                    <li>Offer </li>
                                                    <li>Rs. 200-Rs.600 </li>
                                                    <li>Rating 4.0+ </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className='row mt-5'>
                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            
                                        </div>

                                        
                                    </section>

                                    <section className={toggleState === 5 ? 'list-boxes active-list':"list-boxes"}>
                                        <div className='d-flex justify-between mt-4 align-items-center'>
                                            <div className=''>
                                                <h4 className='sub-heading text-orange'>Bread Gem</h4>
                                            <img src='images/heading.png' className='width20'></img>
                                                <ul className='filter-tab'>
                                                    <li>Filters</li>
                                                    <li>Sort By</li>
                                                    <li>Pure Veg </li>
                                                    <li>Offer </li>
                                                    <li>Rs. 200-Rs.600 </li>
                                                    <li>Rating 4.0+ </li>
                                                </ul>
                                            </div>
                                        </div>

                                        

                                        <div className='row mt-5'>
                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food3.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>

                                            <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                <div>
                                                    <span className='d-flex align-items-center'>
                                                        <img src='images/veg.png' className='veg_img ml-2'></img>
                                                        <p className='para text-gray ms-2'>CONTINENTAL</p>
                                                    </span>
                                                    <img src='images/food.jpg' className='width100 radius-8 mt-3 mb-3'></img>
                                                    <p class="normal text-gray">High Protein English Breakfast   </p>
                                                    <div className='d-flex justify-between'>
                                                        <div>
                                                            <h4 className='mt-2 mb-0'>Rs. 129.00</h4>
                                                            <p class="para text-gray">50% OFF </p>
                                                        </div>
                                                        <button className='add-btn'>Add</button>
                                                    </div>
                                                
                                                    {/* <h4 className='mt-2 text-gray text-decoration-line-throug'>Rs. 129.00</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            
                               
                            </div>
                        </div>
                    </div>

                    <div className='sidebar'>
                        <img src='images/cross-button.png' className='cross cursor-pointer' onClick={closeNav}></img>
                        <h4>MACAW FOOD FOREST</h4>
                        <p>Nanjangud Mysuru Main Road,Chamarajanagar Karnataka</p>
                        <hr></hr>

                        <ul className='sidebar-tab'>
                            {/* <li><NavLink to="/login"><img src='images/user.png'></img>Account</NavLink></li> */}
                            <li><NavLink to="/login"><img src='images/login.png'></img>Login</NavLink></li>
                            <li><NavLink to="/register"><img src='images/login.png'></img>Register</NavLink></li>
                        </ul>

                        <div className='sidebar-footer'>
                            <hr></hr>
                            <span className='d-flex '>
                                <p>Powered By</p>
                                <h6 className='ms-2'>Abc</h6>
                            </span>


                        </div>
                    </div>

                    <div className='leftbar'>
                        <img src='images/cross-button.png' className='cross mb-2 cursor-pointer' onClick={closeNav2}></img>
                        <input type='text' placeholder='Search Area' className='mt-5 login_input'></input>

                        <div className='gps-box d-flex p-3 border mt-4 radius-4'>
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <span className='ms-3'>
                                <p className='normal'>Get Current Location</p>
                                <p className='para'>Usnig GPS</p>
                            </span>
                        </div>

                        <div className='gps-box d-flex p-3 border mt-4 radius-4'>
                            <i class="fa fa-home" aria-hidden="true"></i>
                            <span className='ms-3'>
                                <p className='normal'>Home</p>
                                <p className='para'>11601 N Rodney Parham Rd   </p>
                            </span>
                        </div>
                        <button className='procced-btn bg-orange width100 mt-4'>Add Address</button>

                        <div className='leftbar-footer'>
                            <hr></hr>
                            <span className='d-flex '>
                                <p>Powered By</p>
                                <h6 className='ms-2'>Abc</h6>
                            </span>


                        </div>
                    </div>
                </div>
            </section>

            <div className="whatshapp_img">
                <ul className="d-flex align-items-center list-unstyled p-0 m-0">
                <li>
                    <a href="tel:+1 999 999 9999">+1 999 999 9999</a>
                </li>
                <li>
                    <a href="https://api.whatsapp.com/send?phone=+1 999 999 9999&amp;" className="btn-whatsapp-pulse">
                    <i class="fa fa-whatsapp" aria-hidden="true"></i>

                    </a>
                </li>
                <li>
                    <a href="mailto:info@example.com">
                        <i class="fa fa-paper-plane" aria-hidden="true"></i>
                    </a>
                </li>
                </ul>
            </div>
        </>
    )
}
