import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';

import { useLocation } from 'react-router-dom';


export default function Home() {


   let location = useLocation();
  let pathname = location.pathname;
  var myArray = pathname.split('/');

  if(myArray[2] != '' || myArray[2] != undefined )
  {
      let menu_id = myArray[2];
      localStorage.setItem('menu_id', menu_id);



  }


    const Show = () => {
        document.getElementsByClassName('home_input')[0].style.display="flex";
        document.getElementsByClassName('home_input2')[0].style.display="none";
        document.getElementsByClassName('home_buttons')[0].style.display="none";
      };

      const Show2 = () => {
        document.getElementsByClassName('home_input')[0].style.display="none";
        document.getElementsByClassName('home_input2')[0].style.display="none";
        document.getElementsByClassName('home_buttons')[0].style.display="flex";
      };


      const Show3 = () => {
        document.getElementsByClassName('home_input')[0].style.display="none";
        document.getElementsByClassName('home_input2')[0].style.display="flex";
        document.getElementsByClassName('home_buttons')[0].style.display="none";
      };

    return (
        <>
            <section className='hero_section'>
                <div className='container'>
                    <div className='col-md-7 m-auto'>
                        <div className='bg-white p-5 radius-12 mt-8 border'>
                            <div className='home_logo'>
                                <img src='/images/logo.png'></img>
                            </div>
                            <div className='content'>
                                <h1 className='heading'>MACAW FOOD FOREST </h1>
                                <p className=''>Nanjangud Mysuru Main Road,Chamarajanagar <br/>Karnataka</p>
                            </div>

                            <ul className='home_tab'>
                                <li className='border tab1' onClick={Show}><img src='/images/deliver.png' className='width30'></img>Delivery</li>
                                <li className='border tab2' onClick={Show2}><img src='/images/delivery.png' className='width30'></img>Pick Up</li>
                                <li className='border tab3' onClick={Show3}><img src='/images/dinin.png' className='width30'></img>Dine in</li>
                            </ul>

                            <div className='home_input home_input1 mt-2 border'>
                                <input type='text' className=' ' placeholder='Please type your address'></input>
                                <img src='/images/location.png' className=''></img>
                            </div>

                            <div className='home_buttons mt-4'>
                                <button className='btn1'><i class="fa fa-clock-o" aria-hidden="true"></i>Order Later</button>
                               <NavLink to="/"><button className='btn1 ms-4'><i class="fa fa-cutlery" aria-hidden="true"></i> Order Now</button></NavLink>
                            </div>

                            <div className='home_input home_input2 mt-4 border'>
                                <input type='text' className=' ' placeholder='Please type your address'></input>
                                {/* <img src='images/location.png' className=''></img> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
