import React, { useState } from 'react'
import { Link } from 'react-router-dom';




import ModalVideo from 'react-modal-video'
// import "../../../node_modules/react-modal-video/css/modal-video.css";

import { TypeAnimation } from 'react-type-animation';


export default function IndexLandingFour() {
    return (
        <>
            <section className='hero_section m-0 bg-gray height-100vh login-section'>
                
                <div className='container'>
                   <div className='login_section border'>
                    <div className='row login-flex-box'>
                            <div className='col-md-12 col-lg-6'>
                               <div>
                                    <h4 className='normal'>Login</h4>
                                    <p className='para  mt-2'>Login or signup to food forest to order it</p>
                                    <input type='text' placeholder='Your Name' className='mt-4 login_input'></input>
                                    <input type='text' placeholder='Your Number' className='mt-4 login_input'></input>
                                    <input type='text' placeholder='Your Email' className='mt-4 login_input'></input>
                                    {/* <Link to='' className='mt-3'>Create New Account</Link> */}
                                    <button className='mt-4 bg-orange proceed-btn'>Proceed</button>
                               </div>
                            </div>

                            <div className='login_img col-md-12 col-lg-6'>
                                <img src='images/login_img2.avif' className='width100 radius-4'></img>
                            </div>
                    </div>
                   </div>
                </div>
            </section>
        </>
    )
}
