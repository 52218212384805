import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';


import { TypeAnimation } from 'react-type-animation';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../component/Navbar/navbar'

import axios from 'axios';
import BaseURL from '../config/Baseurl';

import { decrementQty,incrementQty,removeFromCart } from "../redux/slices/CartSlice";
import toast,{Toaster} from 'react-hot-toast';
import MenuSidebar from "../component/MenuSidebar";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



export default function Cart() {

    function Tabs(){
        const [toggleState, setToggleState] = useState(1);
        const toggleTab = (index)=>{
            console.log('index')
        }
        
      

    const menu_id = localStorage.getItem('menu_id');
    const logindetails =  localStorage.getItem('logindetails');

    const dispatch = useDispatch();

    const cartItems = useSelector((state)=>state.cart.cart);

    const totalPrice = cartItems.reduce((total,item)=>total+item.qty * item.sell_price_inc_tax,0);

    // console.log(totalPrice);

    const totalQty = cartItems.reduce((totalQty,item)=>totalQty+item.qty,0);


    const [getProducts, setProducts] = useState([]);

    const getData = (sercdata) => {
        // setvalue(sercdata);

        let data = "";

        const config = {
          method: "GET",
          url: BaseURL + "getProduct?search=" + sercdata,
          headers: {
            "Content-Type": "application/json",
            MID: menu_id,
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            console.log(response);
            if (response.data.success == true) {
              setProducts(response.data.data);
            }
            //   console.log(response);
          })
          .catch((err) => {
            console.log(err);
          });
      };


      const [key, setKey] = useState('pickup');

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
    //   const handleShow = () => setShow(true);




    

    // console.log(cartItems);
    return (
        <>
        
         <Toaster position="bottom-right" reverseOrder={false}/>
            <section className='hero_section bg-gray'>
            <Navbar onSearchValue={getData} />

            <ul className='tabs1'>
                <li onClick={toggleTab}>Tab 1</li>
                <li onClick={toggleTab}>Tab 2</li>
                <li onClick={toggleTab}>Tab 3</li>
            </ul>

            <div className='content-man-box'>
                <div className='active-content'>content 1</div>
                <div>content 2</div>
                <div>content 3</div>
            </div>
                <div className='container mt-4'>
                   <div className='row'>
                     <div className='col-md-8'>
                        <div className='radius-8 p-4 border bg-white'>
                            <p className='normal m-auto d-flex text-center justify-center'>Item Added</p>
                            {
                                (cartItems.length >0)?cartItems.map((i,k)=>{
                                    return (
                                        <>
                                             <div className='d-flex justify-between relative mt-4'>
                                                <div>
                                                    <p className='normal'>sdfsdfg</p>
                                                    <p className='normal'>{i.name}asdfasdf</p>
                                                    <p className='para'>asdfasd</p>
                                                </div>
                                                <div className='increase_decrease_button'>
                                                    <ul>
                                                        <li onClick={()=>{ i.qty >1 ? dispatch(decrementQty({"id":i.id})):(i.qty=0) }} >-</li>
                                                        <li>{i.qty}</li>
                                                        <li onClick={()=>i.qty>=1? dispatch(incrementQty({"id":i.id})): (i.qty = 0)}>+</li>
                                                    </ul>
                                                </div>
                                            </div>
                                                    <button className='btn btn-danger btn-sm' onClick={
                                                        ()=>{dispatch(removeFromCart({"id":i.id}))

                                                        toast(`${i.name} Removed !`,{
                                                            icon: '🔥',
                                                        });

                                                    }}><i className='fa fa-trash'></i></button>
                                        </>
                                    )

                                }): 
                                <div className="d-flex justify-between relative align-items-center mt-4">
                                    <div className='d-flex align-items-center'>
                                        <img src='images/menu3.png' className='width10 radius-4'></img>

                                        <div className='ms-3'>
                                            <p className="normal">Plain Roti</p>
                                            <p className="para">25.00</p>
                                        </div>
                                       
                                    </div>
                                    <div className="increase_decrease_button">
                                        <ul>
                                            <li>-</li><li>1</li><li>+</li>
                                        </ul>
                                    </div>
                                </div>

                                
                               
                            }

                                <div className="d-flex justify-between relative align-items-center mt-4">
                                    <div className='d-flex align-items-center'>
                                        <img src='images/menu1.png' className='width10 radius-4'></img>
                                        <div className='ms-3'>
                                            <p className="normal">Chicken Korma</p>
                                            <p className="para">125.00</p>
                                        </div>
                                       
                                    </div>
                                    <div className="increase_decrease_button">
                                        <ul>
                                            <li>-</li><li>1</li><li>+</li>
                                        </ul>
                                    </div>
                                </div>

                            <hr></hr>
                            <textarea placeholder='Add You opion' className='add-option'></textarea>
                        </div>






                              <div className=' p-4 border  radius-8 bg-white mt-4'>
                            <h4>Almost there</h4>
                            <p className='para text-gray'>Please login with your phone number</p>
                           <div className='d-flex justify-between mt-2'>
                            <h4>Items {totalQty}1</h4>
                            <h4>Rs {totalPrice}5</h4>

                            {
                            (logindetails)?<NavLink to="/payment"><button className='procced-btn bg-orange'>Make Payment</button></NavLink>:<NavLink to="/payment"><button className='procced-btn bg-orange'>Procced with number</button></NavLink>
                              }

                           </div>

                        </div>


     </div>
                     <div className='col-md-4'>
                        <div className='border bg-white radius-8 p-4'>
                            <p className='d-flex justify-content-center'>Select Order Type</p>


                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3"
                                >
                                <Tab eventKey="delivery" title="Delivery">
                                    <div>
                                    <textarea placeholder='Enter Your Address' className='delivery-address'></textarea>
                                    </div>

                                    <button className='btn text-white btn-sm mt-3 bg-orange'>Submit</button>
                                </Tab>
                                <Tab eventKey="pickup" title="Pickup">
                                <div className='p-3 minimum_order mt-4'>
                                    <p className='normal text-gray'>Minimum order amount is  Rs 40.00</p>
                                </div>
                                </Tab>
                                <Tab eventKey="dinein" title="Dinein"  >
                                <div className='p-3 minimum_order mt-4'>
                                <p className='normal text-gray'>Minimum order amount is  Rs 10.00</p>
                                </div>
                                    <button className='btn btn-sm text-white mt-3 bg-orange' onClick={()=>setShow(true)}>Add Table</button>
                                </Tab>
                            </Tabs>

                        </div>

                        {/* <div className='border shadow radius-8 p-4 mt-4'>
                            <p className='d-flex justify-content-center'>Savings Corner</p>
                            <button className='apply-coupon mt-3'>Apply Coupon</button>

                        </div> */}



                        {/* <div className='border shadow radius-8 p-4 mt-4'>
                            <p className='d-flex justify-content-center'>Item Added</p>
                           <div className='d-flex mt-4'>
                            <input type='checkbox'></input>
                            <p className='normal ms-3'>Mark as order later</p>
                           </div>
                        </div> */}

                        <div className='border bg-white radius-8 p-4 mt-4'>
                            <p className='d-flex '>Bill Details</p>
                            <div className='d-flex justify-between relative mt-4'>
                                <p className='para text-gray'>Item Total</p>
                                <p className='para text-gray'>Rs.300. {totalPrice}</p>
                            </div>

                            <div className='d-flex justify-between relative mt-2'>
                                <p className='para text-gray'>Item Total</p>
                                <p className='para text-gray'>Rs.300. {totalPrice}</p>
                            </div>

                            

                            {/* <div className='d-flex justify-between relative mt-1'>
                                <p className='normal text-gray'> Total Without Tax</p>
                                <p>Rs {totalPrice}</p>
                            </div> */}

                            {/* <div className='d-flex justify-between relative mt-1'>
                                <p className='normal text-gray'> CGST : 2.50%</p>
                                <p>Rs 2.19</p>
                            </div>

                            <div className='d-flex justify-between relative mt-1'>
                                <p className='normal text-gray'> SGST : 2.50%</p>
                                <p>Rs 2.19</p>
                            </div>

                            <div className='d-flex justify-between relative mt-1'>
                                <p className='normal text-gray'> SGST : 2.50%</p>
                                <p>Rs 2.19</p>
                            </div> */}
                            <hr></hr>

                            <div className='d-flex justify-between relative mt-2'>
                                <p className='para text-gray'>Delivery Tip</p>
                                <button className=' bg-orange p-1 text-white radius-4'>Add Tip</button>
                            </div>

                            <div className='d-flex justify-between relative mt-2'>
                                <p className='para text-gray'>GST Charge</p>
                                <p className=' para text-gray '>Rs. 10.0</p>
                            </div>

                            <button className='procced-btn bg-orange mt-4'>Procced with number</button>

                        </div>
                     </div>
                   </div>
                   <MenuSidebar />
                </div>
            </section>

            {/* modal */}

            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Table No</Modal.Title>
        </Modal.Header>
        <form>
        <Modal.Body>


                <input type="text" className='form-control' placeholder='Enter Table No'></input>
                <input type="date" className='form-control' ></input>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
            </form>
      </Modal>


        </>
    )
}
}
