import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'
// import "../../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from '../component/Navbar/navbar'

import { NavLink } from 'react-router-dom';

import { TypeAnimation } from 'react-type-animation';

import { useDispatch, useSelector } from 'react-redux';
export default function Payment() {
    const closeNav = ()=>{
        document.getElementsByClassName('sidebar')[0].style.width="0%";
        document.getElementsByClassName('sidebar')[0].style.opacity="0";

      }
      const closeNav2 = ()=>{
        document.getElementsByClassName('leftbar')[0].style.width="0%";
        document.getElementsByClassName('leftbar')[0].style.opacity="0";

      }

    const cartItems = useSelector((state)=>state.cart.cart);

    const totalPrice = cartItems.reduce((total,item)=>total+item.qty * item.sell_price_inc_tax,0);

    // console.log(totalPrice);

    const totalQty = cartItems.reduce((totalQty,item)=>totalQty+item.qty,0);

   const [payment_method,setpayment_method] = useState();

    const paymentMethod = (e)=>{

        console.log(e);

        const method = e.currentTarget.getAttribute("data-payment_method");

        setpayment_method(method);
        // alert(removeId);
      

    }


    return (
        <>
        <Navbar/>
            <section className='hero_section bg-gray'>

                <div className='container mt-4'>
                   <div className='row'>
                     <div className='col-md-7'>
                        <div className='border  radius-8 p-4 bg-white relative'>
                            <p className='normal font-weight-semi-bold'>CHOOSE A PAYMENT METHOD</p>
                            <p className='para text-gray'>2 item • Total: ₹299</p>
                            <div className='d-flex justify-between relative mt-4 payment_box'>
                                <div className='d-flex align-items-center'>
                                    <div className='payment_option'>
                                        <i class="fa fa-plus" aria-hidden="true"></i>

                                    </div>
                                    <span className='ms-4' data-payment_method="paylater" onClick={paymentMethod}>
                                        <p className='normal weight-600'>Add New UPI ID</p>
                                        <p className='para'>Place order now and pay later</p>
                                    </span>
                                </div>
                            </div>

                            <div className='d-flex justify-between relative mt-4 payment_box'>
                                <div className='d-flex align-items-center'>
                                    <div className='payment_option'>
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </div>
                                    <span className='ms-4' data-payment_method="cash" onClick={paymentMethod}>
                                        <p className='normal weight-600'>Add New Card</p>
                                        <p className='para'>Pay once order is delivered </p>
                                    </span>
                                </div>
                            </div>

                            <div className='d-flex justify-between relative mt-4 payment_box'>
                                <div className='d-flex align-items-center'>
                                    <div className='payment_option'>
                                        <img src='images/wallet.png' className='width50'></img>
                                    </div>
                                    <span className='ms-4' data-payment_method="cod" onClick={paymentMethod}>
                                        <p className='normal weight-600'>Wallets</p>
                                        <p className='para'>Paytm PhonePe, Amazone Pay & More</p>
                                    </span>
                                </div>
                            </div>


                            <div className='d-flex justify-between relative mt-4 payment_box'>
                                <div className='d-flex align-items-center'>
                                    <div className='payment_option'>
                                    <img src='images/bank.png' className='width50'></img>

                                    </div>
                                    <span className='ms-4' data-payment_method="online" onClick={paymentMethod}>
                                        <p className='normal weight-600'>Netbanking</p>
                                        <p className='para'>Select from a list of banks</p>
                                    </span>
                                </div>
                            </div>

                            <div className='d-flex justify-between relative mt-4 payment_box'>
                                <div className='d-flex align-items-center'>
                                    <div className='payment_option'>
                                    <img src='images/money.png' className='width50'></img>

                                    </div>
                                    <span className='ms-4' data-payment_method="online" onClick={paymentMethod}>
                                        <p className='normal weight-600'>Cash On Delivery</p>
                                        <p className='para'>Cash on delivery availble for this</p>
                                    </span>
                                </div>
                            </div>

                        </div>

                        {/* <div className='border p-4  radius-8 mt-4 bg-white'>
                            <h4>You're just one step away</h4>
                            <p className='para text-gray'>Great!! Now you can place the order</p>
                           <div className='d-flex justify-between mt-2'>
                            <h4 className='mt-4'>Rs {totalPrice}</h4>
                                <button className='procced-btn'>Place Order</button>
                           </div>

                        </div> */}
                     </div>

                     <div className='col-md-5'>
                        <div className='border  radius-8 p-4 bg-white  md-mt-4'>
                            <p className='d-flex  normal'>Store Cancellation Policy</p>
                            <hr></hr>
                            <p className='mt-4 para'><i class="fa fa-money mr-2" aria-hidden="true"></i> No cancellation fee</p>
                            <p className='mt-2 para'><i class="fa fa-money mr-2" aria-hidden="true"></i> Once the order is accepted you cannot cancel it. </p>
                            <p className='mt-2 para'><i class="fa fa-money mr-2" aria-hidden="true"></i> Once the order is accepted you cannot cancel it. </p>
                            {/* <ul className='tab'>
                                <li>Tab 1</li>
                                <li>Tab 1</li>
                                <li>Tab 3</li>
                            </ul> */}
                        </div>

                        <div className='border  radius-8 p-4 mt-4 bg-white'>
                            {/* <p className='d-flex justify-content-center'><i class="fa fa-phone phone phone" aria-hidden="true"></i>  </p> */}
                            <p className=' normal'>Store Cancellation Policy</p>
                            <hr></hr>

                            <p className='mt-3 para'><i class="fa fa-map-marker mr-2" aria-hidden="true"></i>Nanjangud Mysuru Main Road,Chamarajanagar Karnataka</p>
                            <p className='mt-3 para'><i class="fa fa-phone mr-2" aria-hidden="true"></i>9886041888</p>
                            <p className='mt-3 para'><i class="fa fa-map-marker mr-2" aria-hidden="true"></i>10:00 AM - 11:59 PM</p>
                        </div>

                        {/* <div className='border  radius-8 p-4 mt-4 bg-white'>
                            <div className='d-flex justify-between relative mt-4'>
                                <p >Made Within India</p>
                                <p>Powered By TMBill</p>
                            </div>

                            <div className='d-flex justify-between text-center relative mt-4'>
                                <p >About Us,Terms and conditions,Privacy Policy</p>
                            </div>
                        </div> */}
                     </div>
                   </div>
                   <div className='sidebar'>
                        <img src='images/cross-button.png' className='cross cursor-pointer' onClick={closeNav}></img>
                        <h4>MACAW FOOD FOREST</h4>
                        <p>Nanjangud Mysuru Main Road,Chamarajanagar Karnataka</p>
                        <hr></hr>

                        <ul className='sidebar-tab'>
                            {/* <li><NavLink to="/login"><img src='images/user.png'></img>Account</NavLink></li> */}
                            <li><NavLink to="/login"><img src='images/login.png'></img>Login</NavLink></li>
                            <li><NavLink to="/register"><img src='images/login.png'></img>Register</NavLink></li>
                        </ul>

                        <div className='sidebar-footer'>
                            <hr></hr>
                            <span className='d-flex '>
                                <p>Powered By</p>
                                <h6 className='ms-2'>Abc</h6>
                            </span>


                        </div>
                    </div>

                    <div className='leftbar'>
                        <img src='images/cross-button.png' className='cross mb-2 cursor-pointer' onClick={closeNav2}></img>
                        <input type='text' placeholder='Search Area' className='mt-5 login_input'></input>

                        <div className='gps-box d-flex p-3 border mt-4 radius-4'>
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <span className='ms-3'>
                                <p className='normal'>Get Current Location</p>
                                <p className='para'>Usnig GPS</p>
                            </span>
                        </div>

                        <div className='gps-box d-flex p-3 border mt-4 radius-4'>
                            <i class="fa fa-home" aria-hidden="true"></i>
                            <span className='ms-3'>
                                <p className='normal'>Home</p>
                                <p className='para'>11601 N Rodney Parham Rd   </p>
                            </span>
                        </div>
                        <button className='procced-btn bg-orange width100 mt-4'>Add Address</button>

                        <div className='leftbar-footer'>
                            <hr></hr>
                            <span className='d-flex '>
                                <p>Powered By</p>
                                <h6 className='ms-2'>Abc</h6>
                            </span>


                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
