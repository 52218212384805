import React, { useState } from 'react'

import { useForm } from 'react-hook-form';
import axios from 'axios';
import BaseURL from '../config/Baseurl';
import {  useLocation, useNavigate } from "react-router-dom"


export default function Register() {
    const navigate = useNavigate();
    const location = useLocation();
//    console.log(location.state);


    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();


      const onSubmit = async (inputdata) => {

        // console.log(data);


        let data = inputdata

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'RegisterCustomer',
        headers: {
            'MID': '15',
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios.request(config)
        .then((response) => {

            console.log(response);
            if(response.data.success == true)
                {
                    localStorage.setItem('logindetails', JSON.stringify(response.data.data));

                    navigate('/cart')
                }



        })
        .catch((error) => {
        console.log(error);
        });




      }



    return (
        <>
             <section className='hero_section m-0 bg-gray height-100vh login-section'>
                
                <div className='container'>
                   <div className='login_section border'>
                    <div className='row login-flex-box'>
                            <div className='col-md-12 col-lg-6'>
                               <div>
                                <form method='post' onSubmit={handleSubmit(onSubmit)} >
                                    <h4 className='normal'>Register</h4>
                                    <p className='para  mt-2'>Login or signup to food forest to order it</p>
                                    <input type='text' {...register('first_name', { required: true })}  name='first_name' placeholder='First Name' className='mt-4 login_input'></input>
                                    <span className='text-danger'>{errors.first_name && <p>First Name is required.</p>}</span>
                                    <input type='text' {...register('last_name', { required: true })}  name='last_name' placeholder='Last Name' className='mt-4 login_input'></input>
                                    <span className='text-danger'>{errors.last_name && <p>Last Name is required.</p>}</span>
                                    <input type='number'{...register('phone', { required: true })}  name='phone' placeholder='Your Number' className='mt-4 login_input'></input>
                                    <span className='text-danger'>{errors.phone && <p>Phone Number is required.</p>}</span>
                                    {/* <Link to='' className='mt-3'>Create New Account</Link> */}
                                    <button type ='submit'className='mt-4 bg-orange proceed-btn'>Proceed</button>
                                </form>
                               </div>
                            </div>

                            <div className='login_img col-md-12 col-lg-6'>
                                <img src='images/register.jpg' className='width100 radius-4 height100 object-fit-cover'></img>
                            </div>
                    </div>
                   </div>
                </div>
            </section>
        </>
    )
}
