import React from 'react'
import logo_light from './logo.png';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import * as Icon from 'react-feather';

export default function Navbar(props) {
    let { navClass, navJustify } = props;
    let [isMenu, setisMenu] = useState(false);
    let [manu , setManu] = useState('');
    let location = useLocation();


    const openNav = () => {
        document.getElementsByClassName('sidebar')[0].style.width="320px";
        document.getElementsByClassName('sidebar')[0].style.opacity="1";
        document.getElementsByClassName('sidebar')[0].style.padding="20px";

      };

      const openNav2 = () => {
        document.getElementsByClassName('leftbar')[0].style.width="320px";
        document.getElementsByClassName('leftbar')[0].style.opacity="1";

      };




    
    useEffect(()=>{
        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)

        function windowScroll() {
            const navbar = document.getElementById("topnav");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("nav-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("nav-sticky");
                }
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0)
        return () => {
            window.removeEventListener('scroll', windowScroll);
        };

    },[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])
    

    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };


    return (
     
        <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? '' : navClass === "nav-sticky d-none" ?
        'bg-white dark:bg-slate-900' : ''}`}>
        <div className="container relative">
            

            <div className="menu-extras">
                <div className="menu-item">
                    <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </Link>
                </div>
            </div>

           
           
            <div id="navigation" >
                <div className='row'>
                    <div className='col-md-12 col-lg-7'>
                        <div className='d-flex logo-box'>
                            <div className='logo'>
                                <a href='/'><img src='images/logo.png' className='logo'></img>  </a>
                            </div>

                            <div className='dropdown d-flex align-items-center mt-3 ms-2'>
                                {/* <h4 className='m-0'>Rs 100% OFF</h4> */}
                                <button className='bg-none home-btn text-orange ms-3' onClick={openNav2}><i class="fa fa-home" aria-hidden="true"></i>                                </button>
                                <div className='d-flex ms-3'>
                                    <p className='light-text xs-none' >Open 10:00 AM -11:50 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-5 md-mt-4'>
                        <div className='d-flex justify-end align-items-center relative'>
                            
                            <input className='header_input' placeholder='Search'></input>
                            <a href='/cart'>
                                <img src='images/bag.png' className='header_img order_bag' ></img>
                            </a>
                            <div className='added_order'>1</div>
                            <img src='images/more.png' className='header_img more-img cursor-pointer' onClick={openNav}></img>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </nav >

    

    
    )
}
