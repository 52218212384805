import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Index from './pages/Index';
import Cart from './pages/Cart';
import Login from './pages/Login';
import Register from './pages/Register';
import Error from './pages/Error';
import Payment from './pages/Payment';
import Home from './pages/Home';





export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/index" element={<Index />} />
                <Route path="/"element={<Index />} />
                <Route path="/index"element={<Index />} />
                <Route exact path="/cart" element={<Cart />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/register" element={<Register />} />

                <Route exact path="/home" element={<Home />} />
                <Route exact path="/payment" element={<Payment />} />
                <Route path='/*' element={<Error/>} />

            </Routes>

        </BrowserRouter>
    )
}
